.viewcart {
  h1 {
    margin-bottom: 10px;
    color: gray;
    font-weight: 400;
    font-size: 16px;
  }

  .item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    img {
      width: 150px;
      height: 150px;
      object-fit: cover;
    }

    .details {
      h1 {
        font-size: 12px;
        font-weight: 500;
      }

      p {
        color: gray;
        font-size: 12px;
      }

      .price {
        font-size: 12px;
        color: #2879fe;
        margin-bottom: 10px;
      }
    }

    .delete {
      color: red;
      font-size: 15px;
      cursor: pointer;
    }
  }

  .total {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 20px;
  }

  button {
    width: 250px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    cursor: pointer;
    border: none;
    background-color: #2879fe;
    color: white;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .reset{
    color:red;
    font-size: 12px;
    cursor: pointer;
  }
}
  .product { 
    .left {
      flex: 1.5;
      .images {
        flex: 1.7;
  
        img{
          width: 100%;
          height: 150px;
          object-fit: cover;
          cursor: pointer;
          margin-bottom: 10px;
        }
      }
      .mainImg {
        flex: 5;
  
        img{
          width: 100%;
          max-height: 800px;
          object-fit: cover;
        }
      }
    }
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 30px;
  
      .price{
        font-size: 25px;
        color: #2879fe;
        font-weight: 500;
      }
  
      p{
        font-size: 15px;
        font-weight: 300;
        text-align: justify;
      }
  
      .quantity{
        display: flex;
        align-items: center;
        gap: 10px;
  
        button{
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border: none;
        }
      }

      .add {
        background-color: #2879fe;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        cursor: pointer;
        font-size: 10px;
        color: white;
        display: flex;
        padding: 10px;
        border: none;
        width: 150px;
        gap: 20px;
      }
  
      .links{
        display: flex;
        gap: 20px;
  
        .item{
          display: flex;
          align-items: center;
          gap: 10px;
          color: #2879fe;
          font-size: 14px;
        }
      }
  
      .info{
        display: flex;
        flex-direction: column;
        gap: 10px;
        color: gray;
        font-size: 14px;
        margin-top: 30px;
  
        hr{
          width: 200px;
          border: 1px solid rgb(238, 237, 237);
        }
      }
  
      hr{
        border: 1px solid rgb(238, 237, 237);
      }
  
    }
  }

  .products {
    padding: 30px 50px;
    display: flex;
  
    .left {
      flex: 1;
      position: sticky;
      height: 100%;
      top: 50px;
  
      .filterItem{
        margin-bottom: 30px;
  
        .inputItem{
          margin-bottom: 10px;
          label{
            margin-left: 10px;
          }
        }
      }
    }
    
    .right {
      flex: 3;
  
      .catImg{
        width: 100%;
        height: 200px;
        object-fit: cover;
        margin-bottom: 50px;
      }
    }
  }

  