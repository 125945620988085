  .card{
    .image{
      height: 350px;
      overflow: hidden;
      position: relative;
  
      &:hover{
        .secondImg{
          z-index: 2;
        }
      }
  
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
      }
  
      .mainImg{
        z-index: 1;
      }
  
      span{
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: white;
        color: teal;
        padding: 3px 5px;
        z-index: 3;
        font-weight: 500;
        font-size: 12px;
      }
    }
  
    h2{
      font-size: 16px;
      font-weight: 400;
    }
  
    .prices{
      display: flex;
      gap: 20px;
  
      h3{
        font-size: 14px;
        font-weight: 100;
  
        &:first-child{
          color: gray;
          text-decoration: line-through;
        }
      }
    }
  }

  .cart {
    position: absolute;
    right: 0px;
    top: 52px;
    z-index: 99999;
    background-color: white;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 7px -5px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 7px -5px rgba(0, 0, 0, 0.5);
    
    @media only screen and (max-width: 991.91px) {
      top: 140px;
    }
    h1 {
      margin-bottom: 10px;
      color: gray;
      font-weight: 400;
      font-size: 16px;
    }
  
    .item {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
  
      .details {
        h1 {
          font-size: 12px;
          font-weight: 500;
        }
  
        p {
          color: gray;
          font-size: 12px;
        }
  
        .price {
          font-size: 12px;
          color: #2879fe;
          margin-bottom: 10px;
        }
      }
  
      .delete {
        color: red;
        font-size: 15px;
        cursor: pointer;
      }
    }
  
    .total {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 20px;
    }
  
    button {
      width: 250px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      cursor: pointer;
      border: none;
      background-color: #2879fe;
      color: white;
      font-weight: 500;
      margin-bottom: 20px;
    }
  
    .reset{
      color:red;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .categories {
    display: flex;
    gap: 10px;
  
    .col {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  
    .col-l {
      flex: 2;
    }
  
    .row{
      flex:1;
      display: flex;
      gap: 10px;
      position: relative;
      overflow: hidden;
  
      button{
        position: absolute;
        min-width: 70px;
        width: fit-content;
        height: 30px;
        padding: 2px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        cursor: pointer;
        border: none;
        background-color: white;
        text-transform: uppercase;
        font-weight: 500;
      }
  
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .featuredProducts {
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
  
      h1 {
        flex: 2;
        text-transform: capitalize;
      }
  
      p{
        flex:3;
        color:gray
      }
    }
  
    .bottom{
      display: flex;
      justify-content: center;
    }
  }

  .list{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  