/* Set content for sizes */
.ql-size-small,
.ql-size span[data-value="small"]::before {
  font-size: 12px !important;
}
.ql-size-medium,
.ql-size span[data-value="medium"]::before {
  font-size: 15px !important;
}
.ql-size-large,
.ql-size span[data-value="large"]::before {
  font-size: 18px !important;
}

.ql-editor {
  min-height: 50vh; 
  font-size: 16px;
}
.App .ql-snow .ql-tooltip.ql-flip {
  width: auto;
  left: 17px!important;
  white-space: normal;
}

 @page {
  margin: 1in;
}

@media print {
  body {
    background: none;
  }
  .container .ql-editor {
    width: 6.5in;
    height: 9in;
    padding: 0;
    margin: 0;
    box-shadow: none;
    align-self: flex-start;
  }
  .container .ql-toolbar.ql-snow {
    display: none;
  }
} 